import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import PageHeaders from "./pageHeaders"
import { Stack, Box, Text, Heading, Badge, Button } from "@chakra-ui/core"

const InternalLink = styled(Link)`
  font-family: Montserrat;
  font-weight: 400;
  text-transform: uppercase;
  font-style: italic;
  font-size: 0.8rem;
  color: #312e2e;
  text-decoration: none;
  border-bottom: 1px solid #312e2e;
`

const ExternalLink = styled.a`
  font-family: Montserrat;
  font-weight: 400;
  text-transform: uppercase;
  font-style: italic;
  font-size: 0.8rem;
  color: #312e2e;
  text-decoration: none;
  border-bottom: 1px solid #312e2e;
`

const MainButton = styled(Link)`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  @media (max-width: 640px) {
    font-size: 1rem;
  }
  @media (max-width: 420px) {
    width: 120px;
    text-align: center;
  }
`

function Feature({
    date,
    title,
    intLink,
    intDesc,
    extLink,
    extDesc,
    time,
    ...rest
}) {
    return (
        <Box p={5} shadow="md" borderWidth="1px" mt={8} {...rest}>
            <Heading fontSize="2xl">{date}</Heading>
            <Text
                mt={2}
                mb={4}
                color="gray.500"
                textTransform="uppercase"
                letterSpacing="wide"
                fontSize="md"
                fontWeight="medium"
            >
                {title}
                <br
                    css={css`
            @media (min-width: 640px) {
              display: none;
            }
          `}
                />
                <Badge
                    rounded="lg"
                    px="2"
                    py="2"
                    mt={[2, 0]}
                    variantColor="red"
                    ml={[0, 4]}
                    css={css`
            :empty {
              display: none;
            }
          `}
                >
                    {time}
                </Badge>
            </Text>
            <InternalLink to={intLink}>{intDesc}</InternalLink>
            <ExternalLink href={extLink} target="_blank">
                {extDesc}
            </ExternalLink>
        </Box>
    )
}

const UpcomingEvents = () => (
    <div
        css={css`
      margin-bottom: 4rem;
    `}
    >
        <PageHeaders
            css={css`
        display: flex;
        padding-bottom: 0.5rem;
        align-items: center;
      `}
        >
            Upcoming Events
        </PageHeaders>
        <h3
            css={css`
        margin-top: 1rem;
        font-weight: 300;
      `}
        ></h3>
        <Stack spacing={1}>
            <Feature
                date="BIBLE GROUPS"
                extLink="https://cccares.formstack.com/forms/biblegroups"
                extDesc="Sign-Up for a Bible Group"
                title="Meeting throughout the week"
            />
            <Feature
                date="CELEBRATE RECOVERY"
                extLink="https://crosschurchcares.com/celebraterecovery"
                extDesc="Find Out More"
                title="Wednesdays @ 6:30pm"
            />
            <Feature
                date="MORE EVENTS"
                extLink="https://crosschurchcares.churchcenter.com/registrations/events"
                extDesc="Find Out More"
                title="Upcoming Events"
            />
        </Stack>
    </div>
)

export default UpcomingEvents
