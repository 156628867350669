import React from "react"
import { Link } from "gatsby"
import PopupLive from "../../components/popupLivePhoenix"
import PopupPodcast from "../../components/popupPodcast"
import Layout from "../../components/layoutPhoenix"
import SEO from "../../components/seo"
import SermonSeriesPhoenix from "../../components/sermonSeriesPhoenix"
import GoodFridayLive from "../../components/popupGoodFriday"
import EasterLive from "../../components/popupEaster"
import UpcomingEvents from "../../components/eventsPhoenix"
import MeetThePastorPhoenix from "../../components/meetthePastorPhoenix"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <SermonSeriesPhoenix />
    <UpcomingEvents />
    <MeetThePastorPhoenix />
  </Layout>
)

export default IndexPage
