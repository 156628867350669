import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import LiveImg from "../images/AWC_Square.jpg"

const Popup = styled.div`
  background-color: #edf2f7;
  z-index: 9999;
  position: fixed;
  max-width: 27em;
  bottom: 1em;
  right: 1em;
  color: #333;
  overflow: hidden;
  transition: visibility 1s, opacity 2s ease-out;
  display: flex;
  box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
  @media (max-width: 640px) {
    bottom: 0.25em;
    right: 0.25em;
  }
  @media (max-width: 340px) {
    max-width: 98%;
  }
`

const PopupButton = styled.a`
  color: #f7fafc;
  background: #f07525;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1;
  padding-right: 1rem;
  align-items: center;
  margin-left: 1rem;
  display: flex;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 0.6rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #615b5b;
    border-radius: 4px;
  }
  @media (max-width: 640px) {
    text-align: center;
    margin-right: 0.5rem;
  }
  @media (max-width: 340px) {
    margin-left: 0.5rem;
    margin-left: 1rem;
    font-size: 0.75rem;
    text-align: center;
  }
`

const CloseButton = styled.button`
  color: #f7fafc;
  background: #615b5b;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1;
  padding-right: 1rem;
  align-items: center;
  margin-left: 2rem;
  display: flex;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 0.6rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  cursor: pointer;
  &:hover {
    transition: 0.25s;
    background: #333;
    border-radius: 4px;
  }
  @media (max-width: 640px) {
    text-align: center;
    margin-right: 0.5rem;
  }
  @media (max-width: 340px) {
    margin-left: 0.5rem;
    font-size: 0.75rem;
  }
`
function PopupLive() {
  const [closePopup, setClosePopup] = React.useState(true)

  return (
    <>
      {closePopup && (
        <Popup>
          <img
            src={LiveImg}
            css={css`
              height: 140px;
            `}
          />
          <div>
            <p
              css={css`
                margin-top: 0.75rem;
                margin-left: 1rem;
                margin-right: 0.5rem;
                text-size: 2rem;
                font-weight: 700;
              `}
            >
              Join us for our live service this Sunday at 10:30am.
            </p>
            <div
              css={css`
                display: flex;
                margin-top: 3rem;
                @media (max-width: 340px) {
                  margin-top: 2rem;
                }
              `}
            >
              <PopupButton
                href="https://www.fb.com/crosschurchphx/live"
                target="_blank"
              >
                &rarr; Watch
              </PopupButton>
              <CloseButton onClick={() => setClosePopup(!closePopup)}>
                &times; Close
              </CloseButton>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

export default PopupLive
